<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <Tinymce ref="editor" v-model="tinymceHtml" :height="500" />
    </section>
</template>
<script>
    import {
        getRoleListPage,
    } from "../../api/api";
    import Tinymce from '@/components/Tinymce'
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    export default {
        components: {
            Toolbar,
            Tinymce
        },
        data() {
            return {
                roles: [],
                buttonList: [],
            };
        },
        methods: {

        },
        mounted() {
            tinymce.init({});
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };

</script>


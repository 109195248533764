<template>
    <section>
        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入商品名称" @change="inputChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-cascader placeholder="请选择分类，支持搜索"
                             style="width:230px"
                             v-model="filters.category"
                             :options="categorys"
                             filterable
                             :key="isResouceShow"
                             :props="{ checkStrictly: true, expandTrigger: 'hover' }" @change="categoryChange" clearable></el-cascader>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.storeName" placeholder="请输入店铺名称" @change="storeNameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.type" placeholder="商品类型" @change="selectTypeChange" @clear="selectTypeClear" clearable>
                    <el-option v-for="item in selectType_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectChange" @clear="selectclear" clearable>
                    <el-option v-for="item in select_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="sort" label="排序" width="80"></el-table-column>
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="name" label="名称" width=""></el-table-column>
            <el-table-column prop="batch" label="批次" width="80"></el-table-column>

            <el-table-column prop="categoryName" label="分类" width="100"></el-table-column>
            <el-table-column prop="storeName" label="店铺" width=""></el-table-column>
            <el-table-column prop="price" label="价格" width="80"></el-table-column>
            <el-table-column prop="stock" label="库存" width="80"></el-table-column>
            <el-table-column prop="rebate" label="返利" width="80"></el-table-column>
            <!--状态-->
            <el-table-column prop="isMarketable" label="状态" width="80">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.isMarketable ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.isMarketable ? "上架" : "下架" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="goodsTypes" label="类型" width="200"></el-table-column>
            <!--<el-table-column v-if="!isMarketable" prop="uptime" label="上架时间" wdth="" sortable></el-table-column>
            <el-table-column v-else prop="downtime" label="下架时间" width="" sortable></el-table-column>-->
            <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
            <!--<el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>-->
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>


        <!--新增界面-->
        <el-dialog title="新增商品"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     :rules="addFormRules"
                     ref="addForm">
                <el-descriptions class="margin-top" title="" :column="3" :size="size" border>
                    <el-descriptions-item span="2">
                        <template slot="label">
                            名称
                        </template>
                        <el-input v-model="addForm.name" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            排序
                        </template>
                        <el-input v-model="addForm.sort" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            分类
                        </template>
                        <div class="demo-image__preview">
                            <el-cascader placeholder="请选择，支持搜索功能"
                                         style="width: 100%"
                                         v-model="addForm.categoryIdArr"
                                         :options="categorys"
                                         filterable
                                         :key="isResouceShow"
                                         :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                         v-if="!addLoading"></el-cascader>
                            <el-cascader placeholder="加载中..."
                                         style="width: 400px"
                                         v-if="addLoading"></el-cascader>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            店铺
                        </template>
                        <el-select v-model="addForm.storeId" filterable placeholder="请选择">
                            <el-option v-for="item in storeSeletctList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            批次
                        </template>
                        <div class="demo-image__preview">
                            <el-input v-model="addForm.batch" auto-complete="off"></el-input>
                        </div>
                    </el-descriptions-item>

                    <el-descriptions-item span="3">
                        <template slot="label">
                            描述
                        </template>
                        <el-input v-model="addForm.brief" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                        <template slot="label">
                            类型
                        </template>
                        <el-checkbox-group v-model="goodsTypeList">
                            <el-checkbox-button v-for="item in goodsTypeListAll" :label="item.sValue">{{ item.sKey }}</el-checkbox-button>
                        </el-checkbox-group>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            状态
                        </template>
                        <el-radio-group v-model="addForm.isMarketable">
                            <el-radio :label="true">上架</el-radio>
                            <el-radio :label="false">下架</el-radio>
                        </el-radio-group>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            零售价
                        </template>
                        <el-input v-model="addForm.price" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供货价
                        </template>
                        <el-input v-model="addForm.costPrice" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            成本价
                        </template>
                        <el-input v-model="addForm.cost" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            返利
                        </template>
                        <el-input v-model="addForm.rebate" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            库存
                        </template>
                        <el-input v-model="addForm.stock" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            报损数
                        </template>
                        <el-input v-model="addForm.damageRate" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            开始时间
                        </template>
                        <el-date-picker v-model="addForm.startTime"
                                        type="datetime"
                                        placeholder="选择开始时间">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            结束时间
                        </template>
                        <el-date-picker v-model="addForm.endTime"
                                        type="datetime"
                                        placeholder="选择结束时间">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            起订量
                        </template>
                        <el-input v-model="addForm.minBuy" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            规格
                        </template>
                        <el-input v-model="addForm.spec" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            产地
                        </template>
                        <el-input v-model="addForm.place" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            包装
                        </template>
                        <el-input v-model="addForm.packing" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            总库存
                        </template>
                        <el-input v-model="addForm.totalStock" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            主图
                        </template>
                        <el-upload class="avatar-uploader"
                                   action="/api/picture"
                                   :show-file-list="false"
                                   :on-success="handleAvatarSuccess"
                                   :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            轮播图
                        </template>
                        <el-upload action="/api/picture"
                                   list-type="picture-card"
                                   :on-preview="handlePictureCardPreview"
                                   :on-remove="handleRemove"
                                   :on-success="handleImagesSuccess"
                                   :before-upload="beforeAvatarUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            详情
                        </template>
                        <Tinymce ref="editor" :height="500" />
                    </el-descriptions-item>
                </el-descriptions>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>


        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">

                <el-descriptions class="margin-top" title="" :column="3" :size="size" border>
                    <el-descriptions-item span="2">
                        <template slot="label">
                            名称
                        </template>
                        <el-input v-model="editForm.name" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            排序
                        </template>
                        <el-input v-model="editForm.sort" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            分类
                        </template>
                        <div class="demo-image__preview">
                            <el-cascader placeholder="请选择，支持搜索功能"
                                         style="width: 100%"
                                         v-model="editForm.categoryIdArr"
                                         :options="categorys"
                                         filterable
                                         :key="isResouceShow"
                                         :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                         v-if="!addLoading"></el-cascader>
                            <el-cascader placeholder="加载中..."
                                         style="width: 400px"
                                         v-if="addLoading"></el-cascader>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            店铺
                        </template>
                        <el-select v-model="editForm.storeId" filterable placeholder="请选择">
                            <el-option v-for="item in storeSeletctList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            批次
                        </template>
                        <div class="demo-image__preview">
                            <el-input v-model="editForm.batch" auto-complete="off"></el-input>
                        </div>
                    </el-descriptions-item>

                    <el-descriptions-item span="3">
                        <template slot="label">
                            描述
                        </template>
                        <el-input v-model="editForm.brief" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                        <template slot="label">
                            类型
                        </template>
                        <el-checkbox-group v-model="goodsTypeList">
                            <el-checkbox-button v-for="item in goodsTypeListAll" :label="item.sValue">{{ item.sKey }}</el-checkbox-button>
                        </el-checkbox-group>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            状态
                        </template>
                        <el-radio-group v-model="editForm.isMarketable">
                            <el-radio :label="true">上架</el-radio>
                            <el-radio :label="false">下架</el-radio>
                        </el-radio-group>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            售价
                        </template>
                        <el-input v-model="editForm.price" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供货价
                        </template>
                        <el-input v-model="editForm.costPrice" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            成本价
                        </template>
                        <el-input v-model="editForm.cost" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            返利
                        </template>
                        <el-input v-model="editForm.rebate" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            库存
                        </template>
                        <el-input v-model="editForm.stock" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            报损数
                        </template>
                        <el-input v-model="editForm.damageRate" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            开始时间
                        </template>
                        <el-date-picker v-model="editForm.startTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择开始时间">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            结束时间
                        </template>
                        <el-date-picker v-model="editForm.endTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择结束时间">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            起订量
                        </template>
                        <el-input v-model="editForm.minBuy" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            规格
                        </template>
                        <el-input v-model="editForm.spec" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            产地
                        </template>
                        <el-input v-model="editForm.place" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            包装
                        </template>
                        <el-input v-model="editForm.packing" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            总库存
                        </template>
                        <el-input v-model="editForm.totalStock" auto-complete="off"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            主图
                        </template>
                        <el-upload class="avatar-uploader"
                                   action="/api/picture"
                                   :file-list="fileList"
                                   :show-file-list="false"
                                   :on-success="handleAvatarSuccess"
                                   :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            轮播图
                        </template>
                        <el-upload action="/api/picture"
                                   list-type="picture-card"
                                   :file-list="fileList"
                                   :on-preview="handlePictureCardPreview"
                                   :on-remove="handleRemove"
                                   :on-success="handleImagesSuccess"
                                   :before-upload="beforeAvatarUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-descriptions-item>
                    <el-descriptions-item span="3">
                        <template slot="label">
                            详情
                        </template>
                        <Tinymce v-if="editFormVisible" ref="editor" v-model="editForm.intro" :height="500" />
                    </el-descriptions-item>
                </el-descriptions>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getGoodsListPage,
        getGoodsCategoryTree,
        getAdd,
        addGoods,
        getGoods,
        editGoods,
        putOnGoods,
        putOffGoods,
        removeGoods,
        getStoreSelectList,
        //exportGoodsList,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    import Tinymce from '@/components/Tinymce'

    export default {
        components: { Toolbar, Tinymce },
        data() {
            return {
                select_options: [{
                    value: '1',
                    label: '上架'
                }, {
                    value: '2',
                    label: '下架'
                }],
                selectType_options: [{
                    value: '1',
                    label: '普通商品'
                }, {
                    value: '2',
                    label: '接龙商品'
                }],
                filters: {
                    name: "", //双向绑定搜索内容
                    category: '',
                    state: '',
                    type: '',
                    storeName: '',
                    picker_start: '',
                    picker_end: '',
                },
                datalist: [],
                roles: [],
                categorys: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                goodsTypeListAll: [],
                goodsTypeList: [],

                //新增界面配置信息
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                    //type: [{ required: true, message: "请选择店铺类型", trigger: "blur" }],
                },
                addForm: {},//新增界面数据

                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                editForm: {},//编辑界面数据
                isResouceShow: 0,

                ///图片上传
                ///头像url
                imageUrl: '',
                fileList: [],
                ///弹窗图片
                dialogImageUrl: '',
                dialogVisible: false,

                storeSeletctList: [],
                value: [],
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getGoodss();
            },
            inputChange(val) {
                this.filters.name = val;
            },
            storeNameChange(val) {
                this.filters.storeName = val;
            },
            categoryChange(val) {
                if (val != undefined && val.length > 0) {
                    this.filters.category = val[val.length - 1];
                }
            },
            selectChange(val) {
                this.filters.state = val;
            },
            selectclear() {
                this.filters.state = "";
            },
            selectTypeChange(val) {
                this.filters.type = val;
            },
            selectTypeClear() {
                this.filters.type = "";
            },
            //获取店铺列表
            getGoodss() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    key: this.filters.name,
                    category: this.filters.category,
                    state: this.filters.state,
                    type: this.filters.type,
                    orderField: "sort",
                    orderDirection: "desc",
                };
                this.listLoading = true;
                getGoodsListPage(para).then((res) => {
                    console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //导出表格
            exportExcel() { },


            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的店铺！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该店铺吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        removeGoods(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getGoodss();
                        });
                    })
                    .catch(() => { });
            },

            //上架
            handlePutOn() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要上架该商品！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认上架该商品吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let ids = [row.id];
                        let para = { id: ids };
                        putOnGoods(para).then((res) => {
                            console.log(res);
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "上架成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getGoodss();
                        });
                    })
                    .catch(() => { });
            },

            //下架
            handlePutOff() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要下架该商品！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认下架该商品吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let ids = [row.id];
                        let para = { id: ids };
                        putOffGoods(para).then((res) => {
                            console.log(res);
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "下架成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getGoodss();
                        });
                    })
                    .catch(() => { });
            },

            //显示新增界面
            handleAdd() {
                let that = this;
                that.fileList = [];
                that.goodsTypeList = [];
                that.imageUrl = "";
                getGoodsCategoryTree({}).then((res) => {
                    if (res.data.code == 0) {
                        that.categorys = [];

                        that.categorys.push(res.data.data);
                        that.addFormVisible = true;
                    }
                    this.addLoading = false;
                });
                getAdd({}).then((res) => {
                    if (res.code == 0) {
                        that.goodsTypeListAll = res.data.goodsTypeListAll;
                        console.log(that.goodsTypeListAll);
                    }
                });
            },
            //显示编辑界面
            handleEdit() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的商品！",
                        type: "error",
                    });
                    return;
                }
                getGoods({ id: row.id }).then((res) => {
                    if (res.code == 0) {
                        that.editForm = res.data;
                        that.goodsTypeListAll = res.data.goodsTypeListAll;
                        that.goodsTypeList = res.data.goodsTypeList;
                        that.imageUrl = res.data.image;
                        that.fileList = [];
                        if (res.data.imageList != undefined) {
                            for (var i = 0; i < res.data.imageList.length; i++) {
                                var ii = { name: res.data.imageList[i], url: res.data.imageList[i] };
                                that.fileList.push(ii);
                            }
                        }
                    }
                });

                this.editLoading = false;
                that.editFormVisible = true;
            },
            handleChange(value) {
                console.log(value);
            },

            //************提交方法************

            //新增
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            //NProgress.start();
                            let para = Object.assign({}, this.addForm);
                            if (para.categoryIdArr != undefined) {
                                para.categoryId = para.categoryIdArr.pop();
                            }
                            para.intro = tinymce.activeEditor.getContent();
                            para.goodsTypeList = this.goodsTypeList;
                            para.image = this.imageUrl;
                            var imageList = [];
                            for (var i = 0; i < this.fileList.length; i++) {
                                imageList.push(this.fileList[i].url);
                            }
                            para.imageList = imageList;
                            addGoods(para).then((res) => {
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.addFormVisible = false;
                                    this.getGoodss();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let para = Object.assign({}, this.editForm);
                            if (para.categoryIdArr != undefined) {
                                para.categoryId = para.categoryIdArr.pop();
                            }
                            para.goodsTypeList = this.goodsTypeList;
                            //console.log(this.goodsTypeList);
                            para.image = this.imageUrl;
                            var imageList = [];
                            for (var i = 0; i < this.fileList.length; i++) {
                                imageList.push(this.fileList[i].url);
                            }
                            para.imageList = imageList;
                            para.intro = tinymce.activeEditor.getContent();
                            editGoods(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getGoodss();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },

            selsChange: function (sels) {
                this.sels = sels;
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = res.data.src;
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            handleImagesSuccess(res, file) {
                if (this.fileList == undefined) {
                    this.fileList = [];
                }
                this.fileList.push({ name: res.data.src, url: res.data.src });
            },
            handleRemove(file, fileList) {
                this.fileList = fileList;
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //获取店铺列表
            getStoreSelectList() {
                getStoreSelectList({ data: 0 }).then((res) => {
                    console.log("11111");
                    console.log(res);
                    this.storeSeletctList = res.data;
                });
            },
        },
        mounted() {
            this.getGoodss();
            this.getStoreSelectList();

            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
   /* .input_80 {
        width: 80px;
    }

    .input_100 {
        width: 80px;
    }

    .input_120 {
        width: 80px;
    }*/
</style>

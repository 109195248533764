<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入名称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.mobile" placeholder="请输入电话" @change="mobileChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectChange" @clear="selectclear" clearable>
                    <el-option v-for="item in select_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <v-distpicker :province="filters.province"
                              :city="filters.city"
                              :area="filters.area"
                              @selected="onSelected"
                              @province="onProvinceSelected"
                              @city="onCitySelected"
                              @area="onAreaSelected"
                              :placeholders="placeholders">
                </v-distpicker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column type="index" width=""></el-table-column>
            <el-table-column prop="name" label="名称" width="" sortable></el-table-column>
            <el-table-column prop="address" label="地址" width="" sortable></el-table-column>

            <el-table-column prop="contactName" label="联系人" width="" sortable></el-table-column>
            <el-table-column prop="contactPhone" label="联系电话" width="" sortable></el-table-column>
            <!--店铺门头照-->
            <!--店铺场景照-->
            <!--营业执照-->
            <!--店长照片-->
            <el-table-column prop="bankName" label="银行名称" width="" sortable></el-table-column>
            <el-table-column prop="accountName" label="账户名" width="" sortable></el-table-column>
            <el-table-column prop="cardNumber" label="卡号" width="" sortable></el-table-column>
            <!--状态[0未处理1已处理2已生成]-->
            <el-table-column prop="state" label="状态" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 1 ? 'success' :scope.row.state == 2 ? 'danger':''"
                            disable-transitions>
                        {{ scope.row.state == 1 ? "已处理" :scope.row.state == 2 ? "已生成":"未处理" }}
                    </el-tag>
                </template>
            </el-table-column>
            <!--类型[【0超市，2团长】]-->
            <el-table-column prop="state" label="类型" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.type == 0 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.type == 0 ? "超市" :scope.row.type == 2 ? "团长":"其他" }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="remarks" label="备注" width="" sortable></el-table-column>
            <el-table-column prop="promoter" label="推广员" width="" sortable></el-table-column>
            <!--<el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>-->
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--显示详情-->
        <el-dialog title="详细信息"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-descriptions class="margin-top" title="" :column="3" :size="size" border>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        门头照
                    </template>
                    <div class="demo-image__preview">
                        <el-image style="width: 50px; height: 50px;"
                                  :src="editForm.logoImage"
                                  @click="open(editForm.logoImage)">
                        </el-image>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        店铺名
                    </template>
                    {{ editForm.name }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        联系人
                    </template>
                    {{ editForm.contactName }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-phone-outline"></i>
                        联系电话
                    </template>
                    {{ editForm.contactPhone }}
                </el-descriptions-item>
                <el-descriptions-item span="2">
                    <template slot="label">
                        <i class="el-icon-location-information"></i>
                        联系地址
                    </template>
                    {{ editForm.address }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        银行名称
                    </template>
                    {{ editForm.bankName }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        账户名
                    </template>
                    {{ editForm.accountName }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-location-outline"></i>
                        卡号
                    </template>
                    {{ editForm.cardNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        银行卡
                    </template>
                    <div class="demo-image__preview">
                        <el-image style="width: 100px; height: 100px;"
                                  :src="editForm.cardImage"
                                  @click="open(editForm.cardImage)">
                        </el-image>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        营业执照
                    </template>
                    <div class="demo-image__preview">
                        <el-image style="width: 100px; height: 100px;"
                                  :src="editForm.businessLicenceImage"
                                  @click="open(editForm.businessLicenceImage)">
                        </el-image>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        服务协议
                    </template>
                    <div class="demo-image__preview">
                        <el-image style="width: 100px; height: 100px;"
                                  :src="editForm.agreementImage"
                                  @click="open(editForm.agreementImage)">
                        </el-image>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item span="3">
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        店铺场景图
                    </template>
                    <div class="demo-image__preview">
                        <el-image style="width: 100px; height: 100px;margin-right:10px;"
                                  :src="item"
                                  :preview-src-list="editForm.images"
                                  v-for="item in editForm.images">
                        </el-image>
                    </div>
                </el-descriptions-item>

                <el-descriptions-item span="3">
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        身份证照片
                    </template>
                    <div class="demo-image__preview">
                        <el-image style="width: 100px; height: 100px;margin-right:10px;"
                                  :src="item"
                                  :preview-src-list="editForm.idcardImages"
                                  v-for="item in editForm.idcardImages">
                        </el-image>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item span="3">
                    <template slot="label">
                        <i class="el-icon-tickets"></i>
                        推广人
                    </template>
                    <el-tag size="small"> {{ editForm.promoter }}</el-tag>
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getStoreApplyPageList,
        quickCreateStore,
        removeStoreApply,
        dealStoreApply,
        exportStoreApplyList,
        getEdit
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    import VDistpicker from 'v-distpicker'

    export default {
        components: { Toolbar, VDistpicker },
        data() {
            return {
                select_options: [{
                    value: '1',
                    label: '未处理'
                }, {
                    value: '2',
                    label: '已处理'
                }, {
                    value: '3',
                    label: '已生成'
                }],
                filters: {
                    name: "", //双向绑定搜索内容
                    state: '',
                    province: '',
                    city: '',
                    area: '',
                    mobile: '',
                },
                placeholders: {
                    province: '请选择省份',
                    city: '请选择城市',
                    area: '请选择区县',
                },
                datalist: [],
                roles: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 10,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,
                src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {

                },
                editForm: {},//编辑界面数据
            };
        },
        methods: {
            getImgUrl(icon) {
                if (icon.indexOf('http') > -1) {
                    return icon;
                }
                return 'https://younong.haichuangyibai.com' + icon;
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            nameChange(val) {
                this.filters.name = val;
            },
            mobileChange(val) {
                this.filters.mobile = val;
            },
            selectChange(val) {
                this.filters.state = val;
            },
            selectclear() {
                this.filters.state = "";
            },
            onProvinceSelected(data) {
                this.filters.province = data.value;
            },
            onCitySelected(data) {
                this.filters.city = data.value;
            },
            onAreaSelected(data) {
                this.filters.area = data.value;
            },
            onSelected(data) {
                this.filters.province = data.province.value;
                this.filters.city = data.city.value;
                this.filters.area = data.area.value;
            },
            //获取店铺申请列表
            getDataList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    key: this.filters.name,
                    state: this.filters.state,
                    province: this.filters.province,
                    city: this.filters.city,
                    area: this.filters.area,
                    mobile: this.filters.mobile,
                };
                this.listLoading = true;
                getStoreApplyPageList(para).then((res) => {
                    console.log(res);
                    this.total = res.count;
                    this.datalist = res.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的申请！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该申请吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        //NProgress.start();
                        let para = { id: row.id };
                        removeStoreApply(para).then((res) => {
                            this.listLoading = false;
                            //NProgress.done();
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },


            //导出表格
            exportExcel() {
                let row = this.currentRow;
                //if (!row) {
                //    this.$message({
                //        message: "请选择要导出的店铺！",
                //        type: "error",
                //    });
                //    return;
                //}
                this.$confirm("确认导出申请数据吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    //let para = { id: row.id };
                    exportStoreApplyList({}).then((res) => {
                        this.listLoading = false;
                        if (res.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            console.log(res.data);
                            window.open(res.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });

            },

            //处理申请
            handleDeal() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要处理的申请！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认处理该申请吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        dealStoreApply(para).then((res) => {
                            console.log(res);
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "处理成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            /*this.getDataList();*/
                        });
                    })
                    .catch(() => { });
            },

            //查看商品详情
            handleDetails() {
                let that = this;
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择数据！",
                        type: "error",
                    });
                    return;
                }
                getEdit({ id: row.id }).then((res) => {
                    console.log(res);
                    if (res.code == 0) {

                        that.editForm = res.data;
                    }
                    console.log(that.editForm)
                });
                this.editLoading = false;
                that.editFormVisible = true;
            },


            //一键生成店铺
            quickCreate() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要创建店铺的申请！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认一键创建店铺吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        //NProgress.start();
                        let para = { id: row.id };
                        quickCreateStore(para).then((res) => {
                            this.listLoading = false;
                            //NProgress.done();
                            if (res.code == 0) {
                                this.$message({
                                    message: "创建成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },


            selsChange: function (sels) {
                this.sels = sels;
            },
            open(url) {
                this.$alert('<img src="' + url + '"  alt="" style="max-width:100%;max-height:500px;" />', '', {
                    dangerouslyUseHTMLString: true,
                    closeOnClickModal: true,
                    closeOnPressEscape: true,
                    showConfirmButton: false,
                    center: true,
                });
                //const h = this.$createElement;
                //this.$msgbox({
                //    title: '消息',
                //    message: h('p', null, [
                //        h('span', null, '内容可以是 '),
                //        h('i', { style: 'color: teal' }, 'VNode')
                //    ]),
                //    showCancelButton: true,
                //    confirmButtonText: '确定',
                //    cancelButtonText: '取消',
                //    beforeClose: (action, instance, done) => {
                //        if (action === 'confirm') {
                //            instance.confirmButtonLoading = true;
                //            instance.confirmButtonText = '执行中...';
                //            setTimeout(() => {
                //                done();
                //                setTimeout(() => {
                //                    instance.confirmButtonLoading = false;
                //                }, 300);
                //            }, 3000);
                //        } else {
                //            done();
                //        }
                //    }
                //}).then(action => {
                //    this.$message({
                //        type: 'info',
                //        message: 'action: ' + action
                //    });
                //});
            }
        },
        mounted() {
            this.getDataList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>

<template>
  <section>
    <!--列表-->
    <el-table
      :data="listData"
      highlight-current-row
      v-loading="listLoading"
      @current-change="selectCurrentRow"
      style="width: 100%"
    >
      <el-table-column prop="contentBody" label="标题内容" width>
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.enabled ? 'success' : 'danger'"
            disable-transitions
            >{{ scope.row.enabled ? "正常" : "禁用" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width
      ></el-table-column>
      <el-table-column
        prop="code"
        label="消息编码"
        width
      ></el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="50"
        :total="total"
        style="float: right"
      ></el-pagination>
    </el-col>

  </section>
</template>

<script>
import {
    getMsgListPage
} from "../../api/api";
export default {
  data() {
    return {
      listData: [],
      total: 0,
      page: 1,
      listLoading: false,
      currentRow: null
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    //获取列表
    getList() {
      let _this = this;
      let para = {
        page: _this.page,
        limit: 10
      };
      _this.listLoading = true;
      getMsgListPage(para).then((res) => {
        _this.total = res.data.count;
        _this.listData = res.data.data;
        _this.listLoading = false;
      });
    }
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
</style>

<template>
    <section>

        <!--搜索条件-->
        <!--<searchbar :searchList="buttonList" @callFunction="callFunctionSearch"></searchbar>-->
        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入服务名称" @change="inputChange"></el-input>
            </el-form-item>
            <el-form-item>
                <!--<el-cascader :options="categorys"
                         :props="{ checkStrictly: true }"
                         clearable placeholder="请选择分类"></el-cascader>-->
                <el-cascader placeholder="请选择分类，支持搜索"
                             style="width:230px"
                             v-model="filters.category"
                             :options="categorys"
                             filterable
                             :key="isResouceShow"
                             :props="{ checkStrictly: true, expandTrigger: 'hover' }" @change="categoryChange" clearable></el-cascader>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.storeName" placeholder="请输入服务名称" @change="storeNameChange"></el-input>
            </el-form-item>
            <!--<el-form-item>
                <div class="block">
                    <el-date-picker v-model="filters.picker_end"
                                    type="daterange"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="pickerOptions">
                    </el-date-picker>
                </div>
            </el-form-item>-->
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectChange" @clear="selectclear" clearable>
                    <el-option v-for="item in select_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>


        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <!--<el-table-column type="index" width=""></el-table-column>-->
            <el-table-column prop="id" label="ID" width="" sortable></el-table-column>
            <el-table-column prop="title" label="名称" width="" sortable></el-table-column>
            <el-table-column prop="categoryName" label="分类" width="" sortable></el-table-column>
            <el-table-column prop="storeName" label="店铺" width="" sortable></el-table-column>
            <el-table-column prop="money" label="价格" width="" sortable></el-table-column>
            <el-table-column prop="amount" label="库存" width="" sortable></el-table-column>
            <el-table-column prop="rebate" label="返利" width="" sortable></el-table-column>
            <el-table-column prop="hongbao" label="返红包" width="" sortable></el-table-column>
            <!--状态-->
            <el-table-column prop="isMarketable" label="状态" width="" sortable>
                <template slot-scope="scope">
                    <el-tag :type="scope.row.isMarketable ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.isMarketable ? "上架" : "下架" }}
                    </el-tag>
                </template>
            </el-table-column>
            <!--<el-table-column v-if="!isMarketable" prop="uptime" label="上架时间" wdth="" sortable></el-table-column>
        <el-table-column v-else prop="downtime" label="下架时间" width="" sortable></el-table-column>-->
            <!--<el-table-column prop="updateTime" label="更新时间" width="160" sortable></el-table-column>-->
            <el-table-column prop="createTime" label="创建时间" width="160" sortable></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--新增界面-->
        <el-dialog title="新增"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     label-width="80px"
                     :rules="addFormRules"
                     ref="addForm">
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="addForm.sort" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="title">
                    <el-input v-model="addForm.title" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片" prop="thumbnail">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleAvatarSuccess"
                               :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item prop="categoryIdArr" label="分类" width sortable>
                    <el-cascader placeholder="请选择，支持搜索功能"
                                 style="width: 400px"
                                 v-model="addForm.categoryIdArr"
                                 :options="categorys"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 v-if="!addLoading"></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="addLoading"></el-cascader>
                </el-form-item>

                <el-form-item prop="storeId" label="店铺">
                    <el-select v-model="addForm.storeId" filterable placeholder="请选择">
                        <el-option v-for="item in storeSeletctList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="简介" prop="description">
                    <el-input v-model="addForm.description" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="售价" prop="money">
                    <el-input v-model="addForm.money" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="返利" prop="rebate">
                    <el-input v-model="addForm.rebate" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="返红包" prop="hongbao">
                    <el-input v-model="addForm.hongbao" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="amount">
                    <el-input v-model="addForm.amount" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片集" prop="images">
                    <el-upload action="/api/picture"
                               list-type="picture-card"
                               :on-preview="handlePictureCardPreview"
                               :on-remove="handleRemove"
                               :on-success="handleImagesSuccess"
                               :before-upload="beforeAvatarUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="状态" prop="isMarketable">
                    <el-radio-group v-model="addForm.isMarketable">
                        <el-radio :label="true">上架</el-radio>
                        <el-radio :label="false">下架</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="商品详情" prop="contentBody">
                    <Tinymce ref="editor" :height="500" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>


        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="editForm.sort" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="title">
                    <el-input v-model="editForm.title" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片" prop="thumbnail">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleAvatarSuccess"
                               :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item prop="categoryIdArr" label="分类" width sortable>
                    <el-cascader placeholder="请选择，支持搜索功能"
                                 style="width: 400px"
                                 v-model="editForm.categoryIdArr"
                                 :options="categorys"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 v-if="!addLoading"></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="addLoading"></el-cascader>
                </el-form-item>
                <!--<el-form-item prop="storeId" label="店铺">
        <el-select v-model="editForm.storeName" filterable placeholder="请选择">
            <el-option v-for="item in storeSeletctList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
        </el-select>
    </el-form-item>-->
                <el-form-item label="简介" prop="description">
                    <el-input v-model="editForm.description" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="售价" prop="money">
                    <el-input v-model="editForm.money" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="返利" prop="rebate">
                    <el-input v-model="editForm.rebate" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="返红包" prop="hongbao">
                    <el-input v-model="editForm.hongbao" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="amount">
                    <el-input v-model="editForm.amount" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片集" prop="images">
                    <el-upload action="/api/picture"
                               list-type="picture-card"
                               :file-list="fileList"
                               :on-preview="handlePictureCardPreview"
                               :on-remove="handleRemove"
                               :on-success="handleImagesSuccess"
                               :before-upload="beforeAvatarUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="状态" prop="isMarketable">
                    <el-radio-group v-model="editForm.isMarketable">
                        <el-radio :label="true">上架</el-radio>
                        <el-radio :label="false">下架</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="商品详情" prop="contentBody">
                    <Tinymce v-if="editFormVisible" ref="editor" v-model="editForm.contentBody" :height="500" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getServicesListPage,
        getServicesCategoryTree,
        addServices,
        getServices,
        editServices,
        putOnServices,
        putOffServices,
        removeServices,
        getStoreSelectList,
        //exportServicesList,
    } from "../../api/api";
    import Tinymce from '@/components/Tinymce'
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    import Searchbar from "../../components/Searchbar";
    

    export default {
        components: { Toolbar, Tinymce, Searchbar },
        data() {
            return {
                select_options: [{
                    value: '1',
                    label: '上架'
                }, {
                    value: '2',
                    label: '下架'
                }],

                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },

                filters: {
                    name: "", //双向绑定搜索内容
                    category: '',
                    state: '',
                    storeName: '',
                    picker_start: '',
                    picker_end: '',
                },
                datalist: [],
                roles: [],
                categorys: [],
                total: 0,
                buttonList: [],
                currentRow: null,

                page: 1,
                limit: 20,

                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                //新增界面配置信息
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                    //type: [{ required: true, message: "请选择店铺类型", trigger: "blur" }],
                },
                addForm: {},//新增界面数据

                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                editForm: {},//编辑界面数据
                isResouceShow: 0,

                ///图片上传
                ///头像url
                imageUrl: '',
                //imageList: [],
                fileList: [],
                ///弹窗图片
                dialogImageUrl: '',
                dialogVisible: false,

                storeSeletctList: [],
                value: [],
            };
        },
        methods: {
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                //console.log(item);
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getServicess();
            },
            inputChange(val) {
                this.filters.name = val;
            },
            storeNameChange(val) {
                this.filters.storeName = val;
            },
            categoryChange(val) {
                if (val != undefined && val.length > 0) {
                    this.filters.category = val[val.length - 1];
                }
            },
            selectChange(val) {
                this.filters.state = val;
            },
            selectclear() {
                this.filters.state = "";
            },
            //获取列表
            getServicess() {
                this.imageUrl = "";
                this.fileList = [];
                let para = {
                    page: this.page,
                    limit: this.limit,
                    key: this.filters.name,
                    category: this.filters.category,
                    storeName: this.filters.storeName,
                    startTime: '',
                    endTime: '',
                    state: this.filters.state,
                };
                this.listLoading = true;
                getServicesListPage(para).then((res) => {
                    //console.log(res);
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                });
            },

            //导出表格
            exportExcel() { },


            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的服务！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该服务吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        //NProgress.start();
                        let para = { id: row.id };
                        removeServices(para).then((res) => {
                            this.listLoading = false;
                            //NProgress.done();
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getServicess();
                        });
                    })
                    .catch(() => { });
            },

            //上架
            handlePutOn() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要上架该服务！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认上架该服务吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        putOnServices(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "上架成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getServicess();
                        });
                    })
                    .catch(() => { });
            },

            //上架
            handlePutOff() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要下架该服务！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认下架该服务吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        putOffServices(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "下架成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getServicess();
                        });
                    })
                    .catch(() => { });
            },

            //显示新增界面
            handleAdd() {
                this.addFormVisible = true;
                this.addLoading = false;
            },

            //显示编辑界面
            handleEdit() {
                let that = this;
                that.fileList = [];
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的服务！",
                        type: "error",
                    });
                    return;
                }
                /*console.log(that.editForm.contentBody);*/
                getServices({ id: row.id }).then((res) => {
                    if (res.code == 0) {
                        that.editForm = res.data;
                        that.imageUrl = res.data.thumbnail;
                        if (res.data.imageList != undefined) {
                            for (var i = 0; i < res.data.imageList.length; i++) {
                                var ii = { name: res.data.imageList[i], url: res.data.imageList[i] };
                                that.fileList.push(ii);
                            }
                        }
                        that.editLoading = false;
                        that.editFormVisible = true;
                    }
                });
            },
            handleChange(value) {
                console.log(value);
            },

            //************提交方法************

            //新增
            addSubmit: function () {
                console.log(this.addForm);
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            //NProgress.start();
                            let para = Object.assign({}, this.addForm);
                            if (para.categoryIdArr != undefined) {
                                para.categoryId = para.categoryIdArr.pop();
                            }
                            para.thumbnail = this.imageUrl;
                            para.contentBody = tinymce.activeEditor.getContent();

                            var imageList = [];
                            for (var i = 0; i < this.fileList.length; i++) {
                                imageList.push(this.fileList[i].url);
                            }
                            para.imageList = imageList;
                            addServices(para).then((res) => {
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.addFormVisible = false;
                                    this.getServicess();
                                } else {
                                    console.log(res.msg);
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                };
                            });
                        });
                    }
                });
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let para = Object.assign({}, this.editForm);
                            if (para.categoryIdArr != undefined) {
                                para.categoryId = para.categoryIdArr.pop();
                            }
                            para.thumbnail = this.imageUrl;
                            var imageList = [];
                            for (var i = 0; i < this.fileList.length; i++) {
                                imageList.push(this.fileList[i].url);
                            }
                            para.imageList = imageList;

                            para.contentBody = tinymce.activeEditor.getContent();
                            editServices(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getServicess();
                                } else {
                                    console.log(res.msg);
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                };
                            });
                        });
                    }
                });
            },

            selsChange: function (sels) {
                this.sels = sels;
            },

            handleAvatarSuccess(res, file) {
                this.imageUrl = res.data.src;
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            handleImagesSuccess(res, file) {
                if (this.fileList == undefined) {
                    this.fileList = [];
                }
                this.fileList.push({ name: res.data.src, url: res.data.src });

            },
            handleRemove(file, fileList) {
                this.fileList = fileList;
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //获取店铺列表
            getStoreSelectList() {
                getStoreSelectList({ type: 1 }).then((res) => {
                    this.storeSeletctList = res.data;
                });
            },
            //获取服务分类
            getServicesCategoryTree() {
                let that = this;
                getServicesCategoryTree({}).then((res) => {
                    if (res.data.code == 0) {
                        that.categorys = [];
                        that.categorys.push(res.data.data);
                    }
                });
            },
        },
        mounted() {
            this.getServicess();
            this.getStoreSelectList();
            this.getServicesCategoryTree();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>

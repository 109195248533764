<template>
    <section>

        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入名称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectChange" @clear="selectclear" clearable>
                    <el-option v-for="item in select_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <v-distpicker :province="filters.province"
                              :city="filters.city"
                              :area="filters.area"
                              @selected="onSelected"
                              @province="onProvinceSelected"
                              @city="onCitySelected"
                              @area="onAreaSelected"
                              :placeholders="placeholders">
                </v-distpicker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

        <!--列表-->
        <el-table :data="datalist"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column type="index" width=""></el-table-column>
            <el-table-column prop="storeName" label="名称" width=""></el-table-column>
            <el-table-column prop="address" label="地址" width=""></el-table-column>
            <el-table-column prop="contactName" label="联系人" width=""></el-table-column>
            <el-table-column prop="contactMobile" label="联系电话" width=""></el-table-column>
            <!--状态[0未处理1已处理2已生成]-->
            <el-table-column prop="state" label="状态" width="">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 2 ? 'success' :scope.row.state == 3 ? 'danger':''"
                            disable-transitions>
                        {{ scope.row.state == 3 ? "审核失败" :scope.row.state == 2 ? "审核成功":"审核中" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="promoter" label="推广员" width=""></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

    </section>
</template>

<script>
    import {
        getRoleListPage,
        getStoreInvitePageList,
        auditInviteSuccess,
        auditInviteFail,
        exportStoreInviteExcel
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    import VDistpicker from 'v-distpicker'

    export default {
        components: { Toolbar, VDistpicker },
        data() {
            return {
                select_options: [{
                    value: '1',
                    label: '未处理'
                }, {
                    value: '2',
                    label: '已处理'
                }, {
                    value: '3',
                    label: '已生成'
                }],
                filters: {
                    name: "", //双向绑定搜索内容
                    state: '',
                    province: '',
                    city: '',
                    area: ''
                },
                placeholders: {
                    province: '请选择省份',
                    city: '请选择城市',
                    area: '请选择区县',
                },
                datalist: [],
                roles: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 10,
                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,
                src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {

                },
                editForm: {},//编辑界面数据
            };
        },
        methods: {
            getImgUrl(icon) {
                if (icon.indexOf('http') > -1) {
                    return icon;
                }
                return 'https://younong.haichuangyibai.com' + icon;
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this.filters = {
                    name: item.search,
                };
                this[item.func].apply(this, item);
            },
            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getDataList();
            },
            nameChange(val) {
                this.filters.name = val;
            },
            selectChange(val) {
                this.filters.state = val;
            },
            selectclear() {
                this.filters.state = "";
            },
            onProvinceSelected(data) {
                this.filters.province = data.value;
            },
            onCitySelected(data) {
                this.filters.city = data.value;
            },
            onAreaSelected(data) {
                this.filters.area = data.value;
            },
            onSelected(data) {
                this.filters.province = data.province.value;
                this.filters.city = data.city.value;
                this.filters.area = data.area.value;
            },
            //获取店铺申请列表
            getDataList() {
                let para = {
                    page: this.page,
                    limit: this.limit,
                    key: this.filters.name,
                    state: this.filters.state,
                    province: this.filters.province,
                    city: this.filters.city,
                    area: this.filters.area,
                };
                this.listLoading = true;
                getStoreInvitePageList(para).then((res) => {
                    this.total = res.count;
                    this.datalist = res.data;
                    this.listLoading = false;
                });
            },

            //************按钮事件************

            selsChange: function (sels) {
                this.sels = sels;
            },

            //处理申请
            handleAuditSuccess() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要审核的数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认审核通过吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        auditInviteSuccess(para).then((res) => {
                            console.log(res);
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "审核成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                            this.getDataList();
                        });
                    })
                    .catch(() => { });
            },
            //处理申请
            handleAuditFail() {
                let row = this.currentRow;
                console.log(row);
                if (!row) {
                    this.$message({
                        message: "请选择要审核的数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认审核失败吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        auditInviteFail(para).then((res) => {
                            console.log(res);
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                        });
                        this.getDataList();
                    })
                    .catch(() => { });
            },

            //导出表格
            exportExcel() {
                this.$confirm("确认导出店铺邀请数据吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    let para = {
                        page: this.page,
                        limit: this.limit,
                        key: this.filters.name,
                        state: this.filters.state,
                        province: this.filters.province,
                        city: this.filters.city,
                        area: this.filters.area,
                    };
                    exportStoreInviteExcel(para).then((res) => {
                        this.listLoading = false;
                        if (res.data.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            window.open(res.data.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });

            },
            open(url) {
                this.$alert('<img src="' + url + '"  alt="" style="max-width:100%;max-height:500px;" />', '', {
                    dangerouslyUseHTMLString: true,
                    closeOnClickModal: true,
                    closeOnPressEscape: true,
                    showConfirmButton: false,
                    center: true,
                });
               
            }
        },
        mounted() {
            this.getDataList();
            getRoleListPage().then((res) => {
                this.roles = res.data.data;
            });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style scoped>
</style>
